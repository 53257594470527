.certificaciones-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.certificaciones-table {
    margin-bottom: 3rem;
}

.temario-item {
    display: flex;
    align-items: center;
}

.temario-icon {
    color: green;
    margin-right: 0.5rem;
}

/* Línea de Tiempo */
.timeline-container {
    padding: 2rem;
}

.timeline {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.timeline-step {
    flex: 1 1 200px;
    margin: 1rem;
}

.timeline-step .card {
    text-align: center;
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 768px) {
    .certificaciones-table {
        overflow-x: auto;
    }
}
