.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    background-color: var(--primary-color);
    color: white;
    min-height: 40vh;
    position: relative;
}

.c-white{
    color: white !important;
}

.hero-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.hero-carousel {
    width: 100%;
    max-width: 1200px;
    min-height: 50vh; /* 70% de la altura de la pantalla */
    overflow: hidden;
    position: relative; /* Importante para el ajuste interno */
    text-align: center;
}

.carousel-image {
    width: 70%;
    height: 70%;
    object-fit: cover; /* Mantiene la proporción sin distorsión */
    text-align: center;
}

.carousel-item {
    height: 100%;
}

.fixed-controls {
    position: absolute;
    top: 250px !important; /* Centra verticalmente */
    transform: translateY(-50%); /* Ajusta la posición para el centro exacto */
    z-index: 10; /* Asegúrate de que los botones estén encima del contenido */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para visibilidad */
    border-radius: 50%; /* Opcional: hace que los botones sean circulares */
    width: 50px; /* Tamaño del botón */
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-control-prev {
    left: 10px; /* Espaciado desde el borde izquierdo */
  }
  
  .carousel-control-next {
    right: 10px; /* Espaciado desde el borde derecho */
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 40px; /* Ajusta el tamaño del icono */
    height: 40px;
  }


@media (min-width: 768px) and (max-width: 1537px) {
      /* Pantallas medianas como tablets en horizontal o laptops pequeñas */
    .carousel-image {
        width: 60%;
        
    }

    .hero-section {
        min-height: 50vh !important;
        margin-bottom: 64px;
    }


  }

@media (max-width: 767.98px) {

    .hero-section {
        min-height: 40vh !important;
        margin-bottom: 64px;
    }

    .hero-row {
        justify-content: center;
        margin: 1rem;
    }

    .hero-carousel {
        justify-items: center;
        align-items: center;
        display: flex;
        min-height: 40 vh !important; /* 70% de la altura de la pantalla */
    }

    .carousel-image {
        width: 100%;
        height: 100%;
    }
}