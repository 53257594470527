.carousel-container {
    position: relative;
    margin: 0 auto;
    padding: 0 50px; /* Espacio para las flechas */
}

.carousel-container .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.carousel-container .carousel-cards {
    display: flex;
    transition: transform 0.3s ease-in-out;
}

.carousel-container .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 45% !important;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
}

.carousel-container .card-header {
    font-size: 1.5em;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.carousel-container .header-primary {
    background-color: var(--primary-color);
    color: white;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

.carousel-container .header-secondary {
    background-color: black;
    color: white;
    border-radius: 10px 10px 0 0;
}

.carousel-container .card-body {
    padding: 20px;
    overflow-y: auto;
    flex: 1;
}

.carousel-container .card p {
    margin: 15px 0;
    color: #555;
}

.carousel-container .card h4 {
    font-size: 1.2em;
    margin-top: 20px;
}

.carousel-container .card ul {
    list-style-type: none;
    padding: 0;
}

.carousel-container .card ul li {
    margin-bottom: 10px;
}

.carousel-container .conocer-mas-link {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
}

.carousel-container .card-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f9fa;
}

.carousel-container .footer-left {
    font-size: 1.1em;
}

.carousel-container .footer-right {
    cursor: pointer;
}

/* Flechas */
.carousel-container .carousel-button {
    background: transparent;
    border: none;
    font-size: 2em;
    cursor: pointer;
    color: var(--primary-color);
}

.carousel-container .carousel-button.prev {
    left: 0;
}

.carousel-container .carousel-button.next {
    right: 0;
}

.carousel-container .Card-Button-P {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media (max-width: 767.98px) {
    .carousel-container {
        padding: 0 10px; /* Espacio para las flechas */
    }

    .carousel-container .carousel-button {
        position: absolute;
        top: 40px;
        transform: translateY(-50%);
        font-size: 2em;
    }

    .carousel-container .carousel-button.prev {
        left: 0px;
    }

    .carousel-container .carousel-button.next {
        right: 0px;
    }

    .carousel-container .card {
        width: 90% !important; /* Ancho completo en pantallas pequeñas */
    }
}
