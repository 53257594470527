.services-section {
    padding: 3rem 0;
    min-height: 80vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

.ServicesCard-Item .card{
 min-height: 50vh;
 text-align: justify;
}

.service-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    background-color: var(--primary-color) !important; /* Cambia a rojo */
    color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 2rem;
}

.service-icon {
    font-size: 2.5rem;
}

.service-number {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.service-title {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.5rem;
    text-align: center;
}

.ServicesCard-Item .card .service-card .card-body {
    padding: 1.5rem;
    text-align: justify !important;
    font-size: 0.95rem;
    color: var(--secondary-color);
    flex-grow: 1;
}

.ServicesCard-Item .card .service-card .card-body p {
    text-align: justify !important;
}

@media (min-width: 768px) and (max-width: 1537px) {
    /* Pantallas medianas como tablets en horizontal o laptops pequeñas */
   
    .ServicesCard-Item .card{
        min-height: 60vh;
       }
       
       .services-section {
        min-height: 100vh;
    
    }
    
}



@media (max-width: 767.98px) {
    .service-title {
        font-size: 1.5rem;
    }
    
    .service-icon {
      display: none;
    }
    

    .services-section {
        padding: 0;
        min-height: 50vh;
    }

    .ServicesCard-Item .card{
        min-height: 50vh;
        text-align: justify !important;
        width: 90vw;
       }

       .service-card {
        text-align: justify !important;
        width: 90vw;
    }
}