.header-navbar {
  background: linear-gradient(to bottom, 
      white 85%, /* 75% blanco */
      var(--primary-color) 100% /* Degradado al color primario */
  ) !important;
  box-shadow: 0px 6px 10px rgba(146, 2, 2, 0.61); /* Sombra blanca */
  color: var(--secondary-color) !important;
  padding: 0px !important;
}

  
  .header-navbar .navbar-brand {
    color: var(--secondary-color) !important;
  }
  
  .nav-link-custom {
    color: var(--secondary-color) !important;
  }
  
  .nav-link-custom:hover, .nav-link-custom:focus {
    text-decoration: underline;
    font-weight: 600;
    color: var(--primary-color) !important;
  }
  
  .nav-link-custom.active {
    text-decoration: underline;
    font-weight: 600;
    color: var(--primary-color) !important;
  }
  
