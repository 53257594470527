.invitacion-descuento {
    background-color: var(--primary-color); /* Rojo */
    width: 99vw;
    padding: 2rem 0;
    margin: 0;
  }
  
  .invitacion-descuento h2 {
    color: #fff;
  }
  
  .label-text {
    text-align: justify;
  }
  
  @media (max-width: 991px) {
    .label-text {
      padding-left: 0;
      text-align: center;
    }
  }
  
  .btn-white-red {
    background-color: #fff !important;
    color: var(--primary-color) !important;
    border: none;
    padding: 0.75rem 1.5rem;
    font-weight: bold;
  }
  
  .btn-white-red:hover {
    transform: scale(1.03);
  }
  