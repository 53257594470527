.video-section {
    padding: 2rem 0;
  }
  
  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    width: 100%;
  }
  
  .video-content {
    width: 80%;
    max-width: 700px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsividad para pantallas pequeñas */
  @media (max-width: 767.98px) {
    .video-content {
      width: 95%;
    }
  }
  