footer {
    background-color: var(--secondary-color);
    color: white;
  }
  
  footer .container {
    max-width: 1200px;
  }
  
  footer .col-md-4 {
    text-align: center;
  }
  
  footer .col-md-4 iframe {
    margin: 0 auto;
  }

  .footer-small-text {
    font-size: 0.8rem;
  }

  .text-md-left{
    text-align: left !important;
  }
  
 

  @media (max-width: 768px) {
    footer .row {
      text-align: center;
    }

    .text-Center-custom, .text-md-left{
        text-align: center !important;
    }
  }


  