:root {
  --primary-color: #d32f2f; /* Rojo */
  --secondary-color: #000000; /* Negro */
  --font-family: 'Source Serif Pro', serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family);
  background-color: #ffffff;
  color: var(--secondary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-light{
  background-color: #f8f7f7 !important;
}