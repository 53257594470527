h1 {
    color: var(--primary-color) !important;
  }
  
  .btn-outline-primary {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
  
  .btn-outline-primary:hover {
    color: white !important;
    background-color: var(--primary-color) !important;
  }
  
  .rockola  .card {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .rockola  .card:hover {
    transform: scale(1.01); /* Efecto de agrandamiento */
    z-index: 10; /* Asegura que esté sobre otras tarjetas */
  }
  
  .rockola  .card-header {
    font-size: 1.2rem;
    padding: 10px;
    text-align: center;
  }
  
  .rockola  .card-body {
    padding: 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .rockola  .card-footer {
    padding: 10px;
    background-color: #f8f9fa;
  }
  
/* Pantallas pequeñas */
@media (max-width: 767.98px) {
    .rockola .card {
      
      min-width: 60vw !important; /* Limitar a 100% para evitar desbordamiento */
      max-width: 60vw !important; /* Limitar a 100% para evitar desbordamiento */
      margin: 0 auto; /* Centrar la tarjeta */
    }
  
    .rockola  .card-header {
      font-size: 1rem; /* Ajusta el tamaño de la fuente para pantallas pequeñas */
    }
  
    .rockola  .card-body {
        text-align: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
      }
  }
  