.contact-form-section {
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-form-section h2 {
    color: var(--primary-color);
  }
  
  .contact-form-section .img-fluid {
    border-radius: 15px;
    object-fit: cover;
  }
  
  button.btn-primary {
    background-color: var(--primary-color);
    border: none;
  }
  
  button.btn-primary:hover {
    background-color: #b71c1c;
    color: white;
  }
  