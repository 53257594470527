.floating-whatsapp {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    padding: 0.8rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1000;
    width: 200px;
  }
  
  .floating-whatsapp:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .whatsapp-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .floating-text {
    background-color: white;
    color: black;
    padding: 0.1rem;
    border-radius: 10px;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  @media (max-width: 576px) {
    .floating-text {
      display: none;
    }

    .floating-whatsapp {
        width: 50px;
        padding: 0.3rem;
        opacity: 0.9;
      }
    


      .whatsapp-icon {
        margin-bottom: 0.1rem;
      }
  }
  