.inscripcion-pasos-container {
    background-color: var(--primary-color); /* Fondo rojo */
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  
  .steps-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .step-circle {
    width: 250px;
    height: 250px;
    background-color: white;
    border-radius: 08%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    flex-direction: column;
    font-weight: bold;
    color: black;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.5s ease-in, transform 0.5s ease-in;
  }
  
  .step-circle.visible {
    opacity: 1;
    transform: scale(1);
  }
  
  .step-text {
    font-size: 1rem;
    line-height: 1.3;
    padding: 2px;
  }
  
  @media (max-width: 768px) {
    .steps-wrapper {
      flex-direction: column;
      gap: 1rem;
    }
  }
  