.a-quienes-buscamos-section {
    padding: 3rem 0;
    min-height: auto;
}

.text-list ul {
    list-style: none;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; /* Distribuye el espacio de forma igualitaria */
}

.text-list li {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin-top: 10px;
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.check-icon {
    color: var(--primary-color);
    margin-right: 0.5rem;
}

.a-quienes-buscamos-image {
    width: 60%;
    
    object-fit: cover;
    border-radius: 8px;
    opacity: 0;
    animation: fadeInImage 1s forwards;
}

/* Responsivo para pantalla pequeña */
@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }
}

/* Animaciones */
@keyframes fadeIn {
    0% { opacity: 0; transform: translateX(-20px); }
    100% { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInImage {
    0% { opacity: 0; transform: scale(0.95); }
    100% { opacity: 1; transform: scale(1); }
}

.fade-in {
    animation: fadeIn 1s forwards;
}
