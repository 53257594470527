.mapa-container {
    padding: 2rem;
    margin: 0 auto;
    min-height: auto;
  }
  
  .contact-info {
    font-size: 1rem;
  }
  
  .contact-info h3 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .contact-info p {
    margin: 0.5rem 0;
  }
  
  .contact-links {
    margin-top: 1rem;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
  
  .contact-item .icon {
    color: var(--primary-color);
    margin-right: 0.5rem;
  }
  
  .contact-item span,
  .contact-item a {
    color: black;
    text-decoration: none;
  }
  
  .contact-item a:hover {
    text-decoration: underline;
  }
  
  .btn-share {
    background-color: var(--primary-color) !important;
    color: white !important;
    width: 100%; /* Botón ocupa todo el ancho */
    display: flex;
    align-items: center;
    border: none;
    padding: 0.75rem 1rem;
    justify-content: center;
    font-weight: bold;
  }
  
  .btn-share:hover {
    transform: scale(1.02);
  }
  
  .mapa-container h2 {
    color: var(--primary-color);
  }
  
  .conocer{
    background-color: #611232 !important;
    color: white !important;
  }

  .conocer h2, .conocer h3, .conocer a{
    color: white !important;
  } 